import React, { useState, useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import VendorsTable from "./VendorsTable";
import Snackbar from "components/shared/Snackbar";
import LoaderButton from "../shared/LoaderButton";
import Modal from "components/shared/Modal";
import "./vendors.scss";

import { useDispatch, useSelector } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import { loggedInUserState } from "redux/userSlice";
import { vendorState, vendorActions } from "redux/vendorSlice";
import { useNavPath, getNavigationPath, NAVIGATED_FROM } from "constants/routes";
import { getProjectLabel, getVendorLabel } from "constants/organization_types"
import { client_mode, getDefaultTemplates } from "constants/client_properties";
import certificate_actions from "constants/certificate_actions";
import { clientActions } from "redux/clientSlice";
import useVendorActivities from "hooks/useVendorActivities";

export default function Vendors({ onAddVendor }) {
  const { isAdmin, loggedInUser } = useSelector(loggedInUserState);
  const dispatch = useDispatch();
  const [vendorActivities, addVendorActivity] = useVendorActivities();
  const { userOrgType, selectedClient } = useSelector(organizationState);
  const { vendors } = useSelector(vendorState);
  const clientMode = selectedClient?.mode;

  const categories = useSelector((state) => state.client.categories[selectedClient?.clientID]);
  const projects = useSelector((state) => state.client.projects[selectedClient?.clientID]);
  useEffect(() => {
    if (selectedClient?.clientID) {
      if (clientMode === client_mode.category && !categories) {
        dispatch(clientActions.fetchCategories(selectedClient.clientID));
      } else if (clientMode === client_mode.project && !projects) {
        dispatch(clientActions.fetchProjects(selectedClient.clientID));
      }
    }
  }, [selectedClient?.clientID]);

  const navigate = useNavigate();
  const NAV_PATH = useNavPath(userOrgType);
  const navToVendor = (vendorId) => {
    const destination = getNavigationPath(NAV_PATH.CLIENT_VENDOR_DETAILS, {
      clientId: selectedClient.organizationID,
      vendorId,
      referrer: NAVIGATED_FROM.VENDORS,
    });
    const referrer = getNavigationPath(NAV_PATH.CLIENT_VENDORS, {
      clientId: selectedClient.organizationID,
    });
    navigate(destination, { state: { referrer } });
  };

  const [saving, setSaving] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [selectedClientVendors, setSelectedClientVendors] = useState({});
  const [isRequestConfirmationModalOpen, setIsRequestConfirmationModalOpen] = useState(false);
  const [sendMultipleRequestsLoader, setSendMultipleRequestsLoader] = useState(false);
  const [message, setMessage] = useState();

  const vendorLabel = getVendorLabel(selectedClient);
  const projectLabel = getProjectLabel(selectedClient);

  useEffect(() => {
    if (selectedClient?.clientID) {
      initialLoadVendor(selectedClient.clientID);
    }
  }, [selectedClient?.clientID]);

  async function initialLoadVendor(clientID) {
    setInitialLoading(true);
    await dispatch(vendorActions.fetchVendors(clientID));
    setInitialLoading(false);
  }

  async function onPreferredClick(clientVendor) {
    setSaving(true);
    await dispatch(
      vendorActions.updateStarredVendor({
        id: clientVendor.id,
        starred: !clientVendor.starred,
      })
    );
    setSaving(false);
  }

  function onSelectedVendorChange(id, value) {
    if (value) {
      setSelectedClientVendors((prev) => {
        const selectedVendor = vendors.find((vendor) => vendor?.id === id && vendor?.notificationStatus !== "DISABLED");
        if (selectedVendor) {
          prev[id] = selectedVendor;
        }
        return { ...prev };
      });
    } else {
      setSelectedClientVendors((prev) => {
        delete prev[id];
        return { ...prev };
      });
    }
  }

  async function onMultipleRequestHandler() {
    setSendMultipleRequestsLoader(true);
    const recordSent = { sent: [], errorSending: [] };
    for (const [key, value] of Object.entries(selectedClientVendors)) {
      const result = await sendRequestEmailToVendor(value, recordSent);
    }
    onEmailSentResult(recordSent.sent, recordSent.errorSending);
    setSendMultipleRequestsLoader(false);
    setIsRequestConfirmationModalOpen(false);
  }

  async function sendRequestEmailToVendor(clientVendor, sentToLog = { sent: [], errorSending: [] }) {
    const { subject, projectList, content, signature } = getRequestTemplate(clientVendor);
    const selectedProject = getCertificateProject(clientVendor);
    const recipients = [clientVendor?.vendor?.email];

    if (selectedClient?.usePrimaryNotificationEmailAddress && selectedClient?.primaryNotificationEmailAddress) {
      recipients.push(selectedClient?.primaryNotificationEmailAddress);
    }

    const uploadLink = await getUploadLink(clientVendor, selectedProject, recipients);
    const emailContentToSend = projectList ? projectList + content : content;

    const result = await dispatch(
      vendorActions.sendEmailToVendor({
        subject,
        content: emailContentToSend,
        signature,
        recipients,
        vendorName: clientVendor?.vendor?.name,
        clientName: selectedClient?.name,
        action: certificate_actions.REQUEST,
        ...(uploadLink ? { uploadLink } : {}),
      })
    );
    if (result?.payload?.success) {
      sentToLog.sent.push(clientVendor?.id);
      addVendorActivity({
        action: "EMAIL_SEND",
        clientVendorID: clientVendor.id,
        vendor: {
          message: "requested certificate from " + clientVendor?.vendor?.email,
          details: {
            emailSubject: subject,
            emailContent: content,
            emailSignature: signature
          }
        },
      });
    } else {
      sentToLog.errorSending.push(clientVendor?.id);
      addVendorActivity({
        action: "EMAIL_SEND",
        clientVendorID: clientVendor.id,
        vendor: {
          message: "failed request for  certificate from " + clientVendor?.vendor?.email,
          details: {
            emailSubject: subject,
            emailContent: content,
            emailSignature: signature
          }
        },
      });
    }
  }

  function getRequestTemplate(clientVendor) {
    const defaultTemplates = getDefaultTemplates({
      vendorName: clientVendor?.vendor?.name,
      clientName: selectedClient?.name,
      projectLabel: projectLabel,
      clientProjects: clientVendor?.projects?.items?.map((project) => {
        const result = projects.find((pr) => project.projectID === pr.id);
        return result?.name;
      }),
    });
    let templatesJson = selectedClient?.templates;
    if (!selectedClient?.templates) {
      templatesJson = defaultTemplates;
    } else if (typeof selectedClient?.templates === "string") {
      templatesJson = JSON.parse(selectedClient?.templates);
    }
    templatesJson = {
      ...defaultTemplates,
      ...templatesJson,
    };

    return {
      ...templatesJson?.[certificate_actions.REQUEST],
      projectList: defaultTemplates?.[certificate_actions.REQUEST]?.projectList,
    };
  }

  function onEmailSentResult(success, error) {
    if (success?.length) {
      setMessage({
        text: `Requests sent successfully to ${success.length} ${vendorLabel}s!`,
        severity: "success",
      });
      success.forEach( vendor => delete selectedClientVendors[vendor])
    }
    if (error?.length) {
      setMessage({
        text: `Unable to send email notification for ${error.length} ${vendorLabel}s!`,
        severity: "error",
        duration: 30000,
      });
    }
  }

  async function getUploadLink (clientVendor, projectID, recipients) {
    let initialCoverageRequirements;
    if (projectID) {
      initialCoverageRequirements = projects.find(project => project.id === projectID)?.requiredCoverages
    }
    if (initialCoverageRequirements) {
      initialCoverageRequirements = {projectID: projectID, clientID: selectedClient.clientID, coverages: initialCoverageRequirements, requestedAt: new Date()}
      initialCoverageRequirements = JSON.stringify(initialCoverageRequirements)
    }
    const result = await dispatch(
      vendorActions.createRequestCertificate({
        clientVendorID: clientVendor.id,
        clientID: selectedClient.clientID,
        sentBy: loggedInUser.email,
        sentTo: recipients,
        ...(projectID ? { projectID } : {}),
        ...(initialCoverageRequirements && { initialCoverageRequirements }),
      })
    );
    return window.location.origin + `/vendor/upload/${selectedClient.id}/${clientVendor?.vendor?.id}/${result.payload.certificateId}`;
  }

  function getCertificateProject(clientVendor) {
    const defaultProject = projects.find((project) => project.isDefault);
    return defaultProject && clientVendor.projects.items.find((project) => project.projectID === defaultProject.id)
      ? defaultProject.id
      : clientVendor.projects.items[0]?.projectID;
  }

  return (
    <>
      <div>
        <Typography component="span" variant="h5">
          {vendorLabel}s
        </Typography>
        <div style={{ float: "right" }}>
          {isAdmin && onAddVendor && (
            <Button size="small" sx={{ mr: 3 }} onClick={onAddVendor}>
              <AddIcon fontSize="small" />
              {`Add A ${vendorLabel}`}
            </Button>
          )}
          {/*<Button size="small">*/}
          {/*  <UploadIcon fontSize="small" />*/}
          {/*  {`Upload Multiple ${vendorLabel}s`}*/}
          {/*</Button>*/}
        </div>
      </div>
      <VendorsTable
        vendors={vendors}
        clientMode={clientMode}
        categories={categories}
        projects={projects}
        navToVendor={navToVendor}
        onPreferredClick={onPreferredClick}
        saving={saving}
        loading={initialLoading}
        onSelectionChange={onSelectedVendorChange}
        selected={selectedClientVendors}
        columns={["requested", "starred", "name", "status", "categories", "projects", "city", "state", "createdAt", "email", "phone"]}
        onRequestHandler={() => setIsRequestConfirmationModalOpen(true)}
        hasMultipleSelectionPermissions={isAdmin}
      />
      <Modal
        open={isRequestConfirmationModalOpen}
        title={`You have selected ${Object.entries(selectedClientVendors).length} vendor${Object.entries(selectedClientVendors).length > 1 ? "s" : ""}`}
        content={
          <>
            <div style={{ marginBottom: "10px" }}>Do you want to request cerficates from following vendors:</div>
            {Object.entries(selectedClientVendors).map(([key, value], index) => (
              <p>
                {index + 1}. {value?.vendor?.name}
              </p>
            ))}
          </>
        }
        actions={
          <Box>
            <Button
              disabled={sendMultipleRequestsLoader}
              onClick={() => {
                setIsRequestConfirmationModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <LoaderButton variant={"contained"} onClick={() => onMultipleRequestHandler()} loading={sendMultipleRequestsLoader}>
              Request Certificates
            </LoaderButton>
          </Box>
        }
      />
      <Snackbar message={message} setMessage={setMessage} />
    </>
  );
}
