import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getVendorActivities, logVendorActivity } from "services/activity";
import { loggedInUserState } from "redux/userSlice";

const fieldsToCompare = ["name", "entityName", "email", "additionalEmails", "phone", "ein", "address1", "address2", "city", "state", "zip", "status", "notificationStatus"];
const emailActivities = ["EMAIL_SEND"];

export default function useVendorActivities (clientVendor) {
  const [originalData, setOriginalData] = useState({});
  const [vendorActivities, setVendorActivities] = useState([]);
  const { loggedInUser } = useSelector(loggedInUserState);
  let userFullName = loggedInUser?.email;
  if (loggedInUser?.firstName || loggedInUser?.lastName) {
    userFullName = `${loggedInUser.firstName}${loggedInUser.firstName ? " " : ""}${loggedInUser.lastName}`;
  }

  const loadVendorActivities = async function (clientVendorID) {
    const result = await getVendorActivities(clientVendor?.id || clientVendorID);
    setVendorActivities(result);
  };

  const addVendorActivity = async function ({
    vendor = {},
    categoryUpdateResult,
    categories,
    projectsUpdateResult,
    projects,
    action = "EDIT",
    clientVendorID,
  }) {
    if (action === "CREATE" && clientVendorID) {
      await logVendorActivity({
        clientVendorID,
        userFullName,
        userID: loggedInUser.id,
        action: "CREATE",
      });
      loadVendorActivities(clientVendorID);
      return;
    } else if (emailActivities.includes(action) && clientVendorID) {
      await logVendorActivity({
        clientVendorID,
        userFullName,
        userID: loggedInUser.id,
        action,
        field: JSON.stringify({ message: vendor.message, details: vendor.details }),
      });
      loadVendorActivities(clientVendorID);
      return;
    }

    const originalVendorInfo = {
      ...originalData?.vendor,
      ...(vendor?.status && { status: originalData?.status }),
      ...(vendor?.notificationStatus && { notificationStatus: originalData?.notificationStatus }),
    } || {};
    const updates = {};
    fieldsToCompare.forEach((key) => {
      if (key === "additionalEmails" && (((vendor[key] && vendor[key].length) || (originalVendorInfo[key] && originalVendorInfo[key].length)) && vendor[key]?.toString() !== originalVendorInfo[key]?.toString())) {
        updates[key] = { prev: originalVendorInfo[key], next: vendor[key] };
      } else if ((String(originalVendorInfo[key]) !== String(vendor[key])) && key !== "additionalEmails") {
        updates[key] = { prev: originalVendorInfo[key], next: vendor[key] };
      }
    });
    if (categoryUpdateResult && categories?.length) {
      const { categoriesAdded = [], categoriesDeleted = [] } = categoryUpdateResult;
      categoriesAdded.forEach((categoryId) => {
        const categoryAdded = categories.find((clientCategory) => categoryId === clientCategory.id);
        if (categoryAdded) {
          updates["Category - " + categoryAdded.name] = { prev: "action", next: "added" };
        }
      });
      categoriesDeleted.forEach((categoryId) => {
        const categoryDeleted = categories.find((clientCategory) => categoryId === clientCategory.id);
        if (categoryDeleted) {
          updates["Category - " + categoryDeleted.name] = { prev: "action", next: "deleted" };
        }
      });
    }
    if (projectsUpdateResult && projects?.length) {
      const { projectsAdded = [], projectsDeleted = [] } = projectsUpdateResult;
      projectsAdded?.forEach((projectId) => {
        const projectAdded = projects.find((clientProject) => projectId === clientProject.id);
        if (projectAdded) {
          updates["Project - " + projectAdded.name] = { prev: "action", next: "added" };
        }
      });
      projectsDeleted?.forEach((projectId) => {
        const projectDeleted = projects.find((clientProject) => projectId === clientProject.id);
        if (projectDeleted) {
          updates["Project - " + projectDeleted.name] = { prev: "action", next: "deleted" };
        }
      });
    }
    if (Object.keys(updates).length) {
      await logVendorActivity({
        clientVendorID: clientVendor.id,
        userFullName,
        userID: loggedInUser.id,
        action: "EDIT",
        field: JSON.stringify(updates),
      });
      loadVendorActivities();
    }
  };

  useEffect(() => {
    if (clientVendor?.id) {
      setOriginalData(clientVendor);
      loadVendorActivities();
    }
  }, [clientVendor]);

  return [vendorActivities, addVendorActivity];
}
