import React, {useEffect, useState} from "react";
import { Image } from "semantic-ui-react";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import {Button, FormControlLabel, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import UploadIcon from "@mui/icons-material/Upload";
import ListItemText from "@mui/material/ListItemText";
import {client_mode} from "constants/client_properties";
import us_states from "constants/us_states"
import {formatPhoneNumber, getPhoneNumberDigits, removeFormat, getZipCodeDigits} from "utils/formUtils";
import { SubtitleInline } from "components/shared/Typography"
import "./index.scss";

export default function VendorForm ({
  projects=[],
  clientMode=client_mode.category,
  vendorData = {},
  setVendorData = () => { },
  vendorDataError = {},
  setVendorDataError = ()=>{},
  vendorProjects,
  updateSection,
  updatedLogo,
  logo,
  vendorLabel,
  projectLabel,
  setUpdatedLogo = ()=>{},
}) {
  const {
    name,
    useEntityName = false,
    entityName,
    address1,
    address2,
    city,
    state,
    zip,
    phone,
    email,
    additionalEmails,
  } = vendorData
  const showAll = updateSection === undefined || updateSection === "all"
  const [additionalEmailAddresses, setAdditionalEmailAddresses] = useState([]);

  useEffect(() => {
    setAdditionalEmailAddresses([...(additionalEmails|| [])]);
  }, []);

  useEffect(()=>{
    if(clientMode === client_mode.project && vendorProjects?.items.length) {
      setVendorData({
        ...vendorData,
        selectedProjects: vendorProjects.items.map(vendorProject => vendorProject.projectID)
      })
    }
  }, [vendorProjects])

  function handleEmailAddressesChange (emails) {
    setAdditionalEmailAddresses(emails)
    setVendorData({
      ...vendorData,
      additionalEmails: emails
    });
  }

  function handleEmailAddressesRemove (index) {
    const updatedEmails = [...additionalEmailAddresses]
    updatedEmails.splice(index, 1)
    setAdditionalEmailAddresses(updatedEmails);
    setVendorData({
      ...vendorData,
      additionalEmails: updatedEmails
    })
  }

  function handleuseEntityName (event) {
    setVendorData({
      ...vendorData,
      [event.target.name]: event.target.checked,
    });
  }

  function updateFormValue (event) {
    const {name: fieldName, value} = event.target
    let updatedValue = removeFormat({name: fieldName, value})
    if(fieldName === 'email') {
      if(!event.target.validity?.valid) {
        setVendorDataError({
          ...vendorDataError,
          email: event.type === 'blur' ? "Please enter a valid email address" : true
        })
      } else {
        setVendorDataError({
          ...vendorDataError,
          email: false
        })
      }
    }

    //IMPORTANT: we can't send empty AWS types like AWS Email, Int
    //this can introduce break of API calls
    
    //set organization details if there is value, otherwise 
    // delete empty fields
    if (value) {
      setVendorData({
        ...vendorData,
        [fieldName]: updatedValue
      });
    } else {
      delete vendorData[fieldName]
      setVendorData({
        ...vendorData,
      });
    }
  }

  function onStateFieldKeyPress (event) {
    const { key } = event
    const findState = us_states.find(state => state.startsWith(key.toUpperCase()))

    if (findState) {
      setVendorData({
          ...vendorData,
          'state': findState
        })
      }
  }
  
  function handleSelectAllProjects(event) {
    setVendorData({
      ...vendorData,
      selectedProjects: Array.from(new Set(projects.map(project => project.id)))
    })
  }

  function handleProjectSelect(event) {
    setVendorData({
      ...vendorData,
      selectedProjects: Array.from(new Set(event.target.value))
    })
  }

  function handleLogoUpdate (event) {
    const file =  event.target.files && event.target.files[0]
    if(file) {
      const reader = new FileReader();
      reader.onload = function(event) {
        setUpdatedLogo({
          content: event.target.result,
          extension: file.name.split(".").pop(),
          type: file.type
        })
      }
      reader.readAsDataURL(file)
    }

  }

  return (
    <>
      <Stack spacing={2} mt={2}>
        {(showAll || updateSection === "info") && (
          <>
            <TextField
              required
              className="vendor-info-input"
              label={vendorLabel + " Name"}
              value={name || ""}
              name={"name"}
              onChange={updateFormValue}
              onBlur={updateFormValue}
              error={name === ""}
              helperText={name === "" ? `Please specify a name for the ${String(vendorLabel).toLowerCase()}` : ""}
            />
            <FormControlLabel 
              control={<Checkbox checked={useEntityName} color="default" onChange={handleuseEntityName} name="useEntityName" />}
              label={
                <Typography variant="subtitle1" color="text.secondary">
                  Use Entity name
                </Typography>
              }
            />
            {useEntityName &&
              <TextField
                required
                className="vendor-info-input"
                label={vendorLabel + " Entity Name"}
                value={entityName || ""}
                name={"entityName"}
                onChange={updateFormValue}
                onBlur={updateFormValue}
                error={useEntityName && entityName === ""}
                helperText={useEntityName && entityName === "" ? `Please specify a legal entity name for the ${String(vendorLabel).toLowerCase()}` : ""}
              />
            }
            <Stack direction="row" spacing={2}>
              <TextField
                required
                className="vendor-info-input"
                label="Email"
                value={email || ""}
                name={"email"}
                onChange={updateFormValue}
                onBlur={updateFormValue}
                type="email"
                error={email === "" || typeof vendorDataError.email === "string"}
                helperText={email === "" || typeof vendorDataError.email === "string" ? "Please enter a valid email address" : ""}
              />
              <TextField
                className="vendor-info-input"
                label="Phone"
                value={formatPhoneNumber(phone)}
                name={"phone"}
                onChange={updateFormValue}
                error={phone && (parseInt(getPhoneNumberDigits(phone)) === 0 || getPhoneNumberDigits(phone).length < 10)}
                helperText={
                  phone && (parseInt(getPhoneNumberDigits(phone)) === 0 || getPhoneNumberDigits(phone).length < 10) ? "Please enter a valid phone number" : ""
                }
              />
            </Stack>
            <ReactMultiEmail
              label="additionalEmails"
              placeholder={<span>Additional Emails</span>}
              emails={additionalEmailAddresses}
              onChange={(_emails) => handleEmailAddressesChange(_emails)}
              validateEmail={(email) => isEmail(email)}
              style={{ padding: "1em" }}
              getLabel={(email, index) => (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => handleEmailAddressesRemove(index)}>
                    ×
                  </span>
                </div>
              )}
            />
          </>
        )}
        {clientMode === client_mode.project && (showAll || updateSection === client_mode.project) && (
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="projects-select-label" error={vendorData?.selectedProjects < 1}>
                Associate with {projectLabel}s *
              </InputLabel>
              <Button sx={{ alignSelf: "end", textTransform: "none" }} onClick={handleSelectAllProjects} handleSelectAllProjects>
                Select All
              </Button>
              <Select
                error={vendorData?.selectedProjects < 1}
                multiple
                labelId="projects-select-label"
                id="projects-select"
                label="Projects"
                value={vendorData.selectedProjects || []}
                renderValue={() =>
                  projects
                    ?.filter((project) => vendorData.selectedProjects?.includes(project.id))
                    ?.map((selectedProject) => <div key={selectedProject.name}>{selectedProject.name}</div>)
                }
                onChange={handleProjectSelect}
              >
                {projects.map((project, index) => (
                  <MenuItem key={project.name + index} value={project.id}>
                    <Checkbox checked={!!vendorData.selectedProjects?.includes(project.id)} />
                    <ListItemText primary={project.name} />
                  </MenuItem>
                ))}
              </Select>
              {vendorData?.selectedProjects?.length < 1 ? <FormHelperText error>Please select one or more {[projectLabel]}s</FormHelperText> : null}
            </FormControl>
          </Stack>
        )}
        {(showAll || updateSection === "info") && (
          <>
            <TextField className="vendor-info-input" label="Address Line 1" value={address1} name={"address1"} onChange={updateFormValue} />
            <TextField className="vendor-info-input" label="Address Line 2" value={address2} name={"address2"} onChange={updateFormValue} />
            <Stack direction="row" spacing={2}>
              <TextField className="vendor-info-input" label="City" value={city} name={"city"} onChange={updateFormValue} />
              <FormControl sx={{ minWidth: 80 }}>
                <InputLabel id="state-input-label">State</InputLabel>
                <Select
                  labelId="state-input-label"
                  className="vendor-info-input"
                  label="State"
                  value={state || ""}
                  name={"state"}
                  onChange={updateFormValue}
                  onKeyPress={onStateFieldKeyPress}
                >
                  <MenuItem value={""}>-</MenuItem>
                  {us_states.map((usState) => (
                    <MenuItem value={usState} key={usState}>
                      {usState}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                className="vendor-info-input"
                label="Zip Code"
                value={getZipCodeDigits(zip)}
                name={"zip"}
                onChange={updateFormValue}
                error={zip && getZipCodeDigits(zip).length !== 5}
                helperText={zip && getZipCodeDigits(zip).length !== 5 ? "Please enter valid Zip Code" : ""}
              />
            </Stack>
            <SubtitleInline>
              {vendorLabel} Logo
              {
                <label htmlFor="upload-logo-input">
                  <input type="file" hidden id={"upload-logo-input"} onChange={handleLogoUpdate} accept="image/png, image/jpeg, image/jpg" />
                  <Button startIcon={<UploadIcon />} component="span">
                    Upload New Logo
                  </Button>
                </label>
              }
            </SubtitleInline>
            {(updatedLogo || logo) && <Image src={updatedLogo ? updatedLogo.content : logo} size="small" />}
          </>
        )}
      </Stack>
    </>
  );
}
