import React, {useState, useEffect, useMemo} from "react";
import Modal from "components/shared/Modal";
import {useNavigate} from "react-router-dom";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
} from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Snackbar from "components/shared/Snackbar";
import VendorForm from "components/shared/VendorForm";
import InsuranceAgentForm from "components/shared/VendorForm/InsuranceAgentForm";
import AutomaticCertificateRequestForm from "./AutomaticCertificateRequestForm";
import AreaProgressLoader from "components/shared/AreaProgressLoader";
import {client_mode, classification_id, getDefaultTemplates} from "constants/client_properties";

import { useSelector, useDispatch } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import { getNavigationPath, useNavPath } from "constants/routes";
import certificate_actions from "constants/certificate_actions";
import { getProjectLabel, getVendorLabel } from "constants/organization_types"
import { vendorActions } from "redux/vendorSlice";
import { clientActions } from "redux/clientSlice";
import { updateLogo } from "services/appResources";
import AmplitudeService, { AMPLITUDE_EVENT } from "services/amplitude";
import useVendorActivities from "hooks/useVendorActivities";

export default function AddVendorWizard({
  open,
  onClose,
  clientMode = client_mode.category,
  vendor = { name: false, email: false },
  loggedInUser
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedClient, userOrgType } = useSelector(organizationState);
  const NAV_PATH = useNavPath(userOrgType)
  const categories = useSelector((state) => state.client.categories[selectedClient?.clientID]);
  const projects = useSelector((state) => state.client.projects[selectedClient?.clientID]);
  const [vendorActivities, addVendorActivity] = useVendorActivities()
  const [modelOpen, setModelOpen] = useState(false);
  const vendorLabel = getVendorLabel(selectedClient);
  const projectLabel = getProjectLabel(selectedClient);
  const steps = [
    {label: `Provide ${vendorLabel} Information`, required: true},
    {label: 'Provide Insurance Agent Information'},
    {label: 'Request Certificates'},
  ];
  useEffect(() => {
    setModelOpen(open)
  }, [open])

  useEffect(() => {
    if(selectedClient?.clientID) {
      if(clientMode === client_mode.category && !categories) {
        dispatch(clientActions.fetchCategories(selectedClient.clientID))
      } else if(clientMode === client_mode.project && !projects) {
        dispatch(clientActions.fetchProjects(selectedClient.clientID))
      }
    }
  }, [selectedClient?.clientID])

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [vendorData, setVendorData] = useState(vendor)
  const [updatedLogo, setUpdatedLogo] = useState()
  const [message, setMessage] = useState()
  const [vendorDataError, setVendorDataError] = useState({})
  const [saving, setSaving] = useState(false)

  const [selectedCoverages, setSelectedCoverages] = useState({
    "vendorDefault": selectedClient?.clientCoverages?.items || []
  })
  const classificationIDKey =  classification_id[clientMode]
  const classifications = clientMode === client_mode.category ? categories : projects
  const vendorClassifications = useMemo(() => {
    classifications?.filter(classification => {
      if(clientMode === client_mode.category) {
        return classification.id === vendorData?.primaryCategory
          || vendorData?.otherCategories?.includes(classification.id)
      }
      // todo - handle project
      return false
    })
  }, [classifications])

  useEffect(() => {
    if(vendorData.primaryCategory) {
      const classCoverages = {}
      const selectedCategories = [
        vendorData.primaryCategory,
        ...(vendorData.otherCategories || [])
      ]
      selectedCategories.forEach(selectedCategoryID => {
        const classCoverage = vendorClassifications
          ?.find(vendorClassification => vendorClassification.id === selectedCategoryID)
          ?.requiredCoverages
        classCoverages[selectedCategoryID] = (typeof classCoverage === "string") ? JSON.parse(classCoverage) : []
        classCoverages[selectedCategoryID] = classCoverages[selectedCategoryID].map(oneClassCoverage => ({
          ...oneClassCoverage,
         [classificationIDKey]: selectedCategoryID // adding category/project ID to the default requirement
        }))
      })
      setSelectedCoverages({
        ...selectedCoverages,
        ...classCoverages
      })
    }
  }, [vendorData])

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function handleClose(showNew) {
    setActiveStep(0)
    setVendorData(vendor)
    setModelOpen(false)
    onClose()
    setUpdatedLogo(undefined)
  }

  async function getUploadLink (vendorId, projectID, clientVendorId, recipients) {
    let initialCoverageRequirements;
    if (projectID) {
      initialCoverageRequirements = projects.find(project => project.id === projectID)?.requiredCoverages
    }
    if (initialCoverageRequirements) {
      initialCoverageRequirements = {projectID: projectID, clientID: selectedClient.clientID, coverages: initialCoverageRequirements, requestedAt: new Date()}
      initialCoverageRequirements = JSON.stringify(initialCoverageRequirements)
    }
    const result = await dispatch(vendorActions.createRequestCertificate({
      clientVendorID: clientVendorId,
      clientID: selectedClient.clientID,
      sentBy: loggedInUser.email,
      sentTo: recipients,
      ...(projectID ? { projectID } : {}),
      ...(initialCoverageRequirements && { initialCoverageRequirements }),
    }))
    return window.location.origin + `/vendor/upload/${selectedClient.id}/${vendorId}/${result.payload.certificateId}`
  }

  async function handleSubmit() {
    if(selectedClient) {
      setSaving(true)
      if (updatedLogo) {
        try {
          const fetched = await fetch(updatedLogo.content)
          const blobbed = await fetched.blob()
          await updateLogo(
            `${vendorData.id}.${updatedLogo.extension}`,
            blobbed,
            updatedLogo.type,
          )
        } catch (err) {
          handleLogoUpdateFailed(err, setUpdatedLogo)
          return
        }
      }
      const input = {...vendorData}
      updatedLogo && (input.logo = `${vendorData.id}.${updatedLogo.extension}`)

      const result = await dispatch(vendorActions.createVendor({
        ...input,
        clientID: selectedClient.clientID,
        selectedCoverages,
        defaultProject: projects?.find(project => project.isDefault)
      }))

      if(result?.payload?.clientVendorID) {
        addVendorActivity({action: "CREATE", clientVendorID: result.payload.clientVendorID})
        
        AmplitudeService.logEvent(
          AMPLITUDE_EVENT.SUBCONTRACTOR_CREATED.eventName,
          AMPLITUDE_EVENT.SUBCONTRACTOR_CREATED.eventProperties({
            clientId: selectedClient?.id,
            clientName: selectedClient?.name,
            label: vendorLabel,
            userEmail: loggedInUser?.email,
          })
        );
      } else {
        setMessage({
          severity: "error",
          text: `Unable to create ${vendorLabel}`
        })
        handleClose()
        setSaving(false)
        return;
      }

      //Send request certificate email notification
      const emailNotificationRecipients = []
      if (vendorData?.sendAutomaticEmailTo?.subcontractor && vendorData?.email) {
        emailNotificationRecipients.push({ email: vendorData?.email, name: vendorData?.name })
        // Add additional emails as recipients
        Array.isArray(vendorData?.additionalEmails) &&
          vendorData?.additionalEmails?.forEach((email) => emailNotificationRecipients.push({ email: email, name: vendorData?.name }));
      } 
      if (vendorData?.sendAutomaticEmailTo?.insuranceAgent && vendorData?.insuranceAgent?.email) {
        emailNotificationRecipients.push({ email: vendorData?.insuranceAgent?.email, name: vendorData?.insuranceAgent?.name })
      }
      if(emailNotificationRecipients.length) {
        const outgoingEmails = [];
        const emailTemplate = certificate_actions.REQUEST
        let templatesJson = selectedClient?.templates || getDefaultTemplates({ clientName:selectedClient?.name, projectLabel: projectLabel })
        if (selectedClient?.templates && typeof selectedClient?.templates  === 'string') {
          templatesJson = JSON.parse(selectedClient?.templates)
        }
        const newCertificateProject = vendorData?.sendAutomaticEmailTo?.emailSelectedProject?.id || projects?.find(project => project.isDefault)?.id || vendorData.selectedProjects[0];
        let uploadLink = await getUploadLink(result.payload.vendorId, newCertificateProject, result?.payload?.clientVendorID, emailNotificationRecipients.map(r => r.email))

        emailNotificationRecipients.forEach(async recipient => {
          outgoingEmails.push(recipient.email);

          await dispatch(vendorActions.sendEmailToVendor({
            subject: templatesJson?.[emailTemplate]?.subject || "",
            content: templatesJson?.[emailTemplate]?.content,
            vendorName: recipient.name,
            clientName: selectedClient?.name,
            recipients: recipient.email,
            action: emailTemplate,
            ...(uploadLink ? {uploadLink} : {})
          }))
          
          AmplitudeService.logEvent(AMPLITUDE_EVENT.CERTIFICATE_REQUESTED.eventName,
            AMPLITUDE_EVENT.CERTIFICATE_REQUESTED.eventProperties({
              userEmail: loggedInUser?.email,
              clientId: selectedClient?.id,
              clientName: selectedClient?.name
            })
          )
        })
        
        addVendorActivity({
          action: "EMAIL_SEND",
          clientVendorID: result.payload.clientVendorID,
          vendor: {
            message: "sent email to " + outgoingEmails.join(','),
            details: {
              emailSubject: templatesJson?.[emailTemplate]?.subject || "",
              emailContent: templatesJson?.[emailTemplate]?.content,
              ...(uploadLink && { certificateLink: uploadLink }),
            }
          }
        })
      }

      
      handleClose()
      setMessage({
        severity: "success",
        text: `Successfully created ${vendorLabel}`
      })
      const destination = getNavigationPath(
        NAV_PATH.CLIENT_VENDOR_DETAILS,
        {clientId: selectedClient.organizationID, vendorId: result.payload.vendorId}
      )
      navigate(destination)
      setSaving(false)
    }
  }

  function handleLogoUpdateFailed(err, setUpdatedLogo) {
    setMessage({
      severity: "error",
      text: "Failed to upload new logo"
    })
    setUpdatedLogo(undefined)
  }

  return (
    <>
      <Snackbar message={message} setMessage={setMessage} />
      <Modal
        open={modelOpen}
        maxWidth={"lg"}
        maxHeight={true}
        onClose={handleClose}
        title={`Add A ${vendorLabel}`}
        content={
          <Box sx={{width: '100%'}}>
            { saving && <AreaProgressLoader /> }
            <Stepper activeStep={activeStep}>
              {steps.map(({label, ...labelProps}, index) => {
                const stepProps = {};
                labelProps.optional = (<Typography variant="caption">{steps[index].required ? "Required":"Optional"}</Typography>);
                labelProps.StepIconProps = {
                  style: { fontSize: "30px" }
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                } else if(activeStep >= index){
                  labelProps.StepIconProps.style.color = "green"
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{mt: 2, mb: 1}}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                  <Box sx={{flex: '1 1 auto'}}/>
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 4}}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0 || saving}
                    onClick={handleBack}
                    sx={{mr: 1}}
                  >
                    <ChevronLeftIcon /> Back
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>

                  {
                    activeStep === steps.length - 1
                    ? (
                      <Button
                        onClick={()=>handleSubmit()}
                        color="success"
                        disabled={saving || vendorDataError?.invalidInsuranceAgentForm}
                      >
                        Finish
                      </Button>
                      )
                    : (
                        <Button
                          onClick={handleNext}
                          color="success"
                          disabled={
                            activeStep === 0 && !(vendorData.name && vendorData.email && !vendorDataError.email && vendorData?.selectedProjects?.length > 0)
                            || activeStep === 1 && vendorDataError?.invalidInsuranceAgentForm
                            || saving
                          }
                        >
                          Next
                          <ChevronRightIcon />
                        </Button>
                      )
                  }
                </Box>
                {
                  activeStep === 0 &&
                  <Box sx={{display: "flex", flexDirection: 'column', pt: 4, pl: 2, pr: 2}}>
                    <VendorForm
                      clientMode={clientMode}
                      categories={categories}
                      projects={projects}
                      vendorData={vendorData}
                      setVendorData={setVendorData}
                      vendorDataError={vendorDataError}
                      setVendorDataError={setVendorDataError}
                      updatedLogo={updatedLogo}
                      setUpdatedLogo={setUpdatedLogo}
                      vendorLabel={vendorLabel}
                      projectLabel={projectLabel}
                    />
                  </Box>
                }
                {
                  activeStep === 1 &&
                    <Box sx={{display: "flex", flexDirection: 'column', pt: 4, pl: 2,}}>
                      <InsuranceAgentForm
                        vendorData={vendorData}
                        setInsuranceAgentData={setVendorData}
                        insuranceAgentDataError={vendorDataError}
                        setInsuranceAgentDataError={setVendorDataError}
                      />
                    </Box>
                }
                {
                  activeStep === 2 &&
                    <Box sx={{display: "flex", flexDirection: 'column', pt: 4, pl: 2,}}>
                      <AutomaticCertificateRequestForm
                          vendorData={vendorData}
                          setSendAutomaticEmailRecipients={setVendorData}
                          projects={projects}
                          clientName={selectedClient?.name}
                          useDefaultProject={selectedClient?.useDefaultProject}
                          defaultProject={projects.filter(p => p?.isDefault)[0]}
                          vendorLabel={vendorLabel}
                          projectLabel={projectLabel}
                      />
                    </Box>
                }
              </React.Fragment>
            )}
          </Box>
        }
      />
    </>
  );
}
